import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalProvider } from './GlobalContext';

function RootLayout() {
  return (
    <React.StrictMode>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<RootLayout />);
