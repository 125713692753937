import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Loading from '../Loading/Loading'
import NewsItem from '../NewsItem/NewsItem'
import { v4 as uuidv4 } from 'uuid'
import { Col, Row } from 'react-bootstrap'
import { header } from '../../config/config'
import { HEADERS, endpointArticleList } from '../../config/api'
import { Container, Header, card } from './index'
import { useParams } from 'react-router-dom'
import { bottomReached } from '../../utils'
import { GlobalContext } from '../../GlobalContext'
import CMSPages from '../CMSPages'

function News() {
  const { category } = useParams()
  const { cats } = useContext(GlobalContext)
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [page, setPage] = useState(1)
  const isBottomReached = useRef(false)
  const totalRef = useRef(0)
  const limit = 12

  document.title = `बाईसाहब: आपके विश्वसनीय समाचारों का स्रोत`

  const capitaLize = string => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1) || 'समाचार'
  }

  function handleScroll(e) {
    if (bottomReached(e) && !isBottomReached.current && page < totalRef.current) {
      isBottomReached.current = true
      setPage(prev => ++prev)
    }
  }

  const updatenews = async (isPageChange = false) => {
    try {
      if (isPageChange) {
        setPageLoading(true)
      } else {
        setLoading(true)
      }
      const seoRes = {
        iId: cats?.filter(ele => ele?.slug?.split('/')?.[1] === category?.replace(/\//g, ''))?.[0]?._id
      }
      const idList = cats.reduce((list, item) => {
        if (item?._id) {
          list.push(item._id);
        }
        return list;
      }, [])
      let response
      if (cats?.length && !seoRes?.iId && idList) {
        response = await axios.post(endpointArticleList(page, limit, seoRes?.iId || '', !seoRes?.iId ? '671f8090784ed66097a5a93d' : ''),
          !seoRes?.iId ? {
            aSimpleCategories: idList
          } : {},
          {
            headers: HEADERS
          })
      } else if (seoRes?.iId) {
        response = await axios.post(endpointArticleList(page, limit, seoRes?.iId || '', ''), {}, {
          headers: HEADERS
        })
      } else {
        return
      }
      const parsedData = response.data?.data?.feed?.articles || []
      totalRef.current = Math.ceil(response.data?.data?.feed?.newsArticleCount / limit)

      if (isBottomReached.current) {
        setArticles(prevArticles => [...prevArticles, ...parsedData])
        isBottomReached.current = false
      } else {
        setArticles(parsedData)
      }

      if (isPageChange) {
        setPageLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  useEffect(() => {
    if ((!category || category === '/' || category === '' || (category !== '/' && category !== '' && cats?.length)) && category !== 'terms-and-condition' && category !== 'privacy-policy') {
      if (page !== 1) {
        updatenews()
      } else {
        updatenews(true)
      }
    } else if (category === 'terms-and-condition' || category === 'privacy-policy') {
      setPageLoading(false)
    }
  }, [cats, page])

  useEffect(() => {
    if (page > 1) {
      setPage(1)
      return
    }
    if ((!category || category === '/' || category === '' || (category !== '/' && category !== '' && cats?.length)) && category !== 'terms-and-condition' && category !== 'privacy-policy') {
      if (page !== 1) {
        updatenews()
      } else {
        updatenews(true)
      }
    } else if (category === 'terms-and-condition' || category === 'privacy-policy') {
      setPageLoading(false)
    }
  }, [category])

  return (
    <>
      {pageLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {category === 'terms-and-condition' || category === 'privacy-policy' ? (
            <CMSPages page={category} />
          ) : (
            <>
              <div style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
                <Header>{header(capitaLize(cats?.filter(ele => ele?.slug?.split('/')?.[1] === category)?.[0]?.name?.replace('-', ' ')))}</Header>
                <Container>
                  <Row>
                    {articles?.length ? (
                      articles?.map(element => (
                        <React.Fragment key={uuidv4()}>
                          {element?.newsType === 'imageArticle' && (
                            <Col sm={12} md={6} lg={4} xl={3} style={card} key={uuidv4()}>
                              <NewsItem
                                title={element?.title}
                                description={element?.description}
                                published={element?.publishedAt}
                                channel={element.source?.name}
                                alt={element?.image?.altText || 'News Article'}
                                publishedAt={element?.publishedAt}
                                imageUrl={
                                  element.newsType === 'imageArticle'
                                    ? element.image?.url
                                    : element.newsType === 'videoArticle'
                                      ? element.ytVideo?.videoThumbnails?.thumbnail300x300
                                      : element.newsType === 'carousel'
                                        ? element.carousel?.slides?.[0]?.url
                                        : element.image?.url
                                }
                                urlNews={element?.source}
                              />
                            </Col>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <div className="m-auto">Articles coming soon...!!!</div>
                    )}
                  </Row>
                </Container>
              </div>
              {loading ? (
                <>
                  <Loading />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default News
