console.log('ENV: ', JSON.stringify(process.env))
const API_DOMAIN = 'https://article.api.baisahab.com/'
// const API_DOMAIN = process.env.REACT_APP_API_URL + '/' || 'https://article.api.dev.baisahab.com/'
export const HEADERS = {
  'client-origin': 'BaiSahab',
  'Content-Type': 'application/json'
}
// const API_SEARCH_DOMAIN = 'https://gnews.io/api/v4/search?q='
export const endpointCatList = () =>
  `${API_DOMAIN}api/category-list?iParentCategoryId=671f8090784ed66097a5a93d`
export const endpointArticleList = (page = 0, limit = 10, iCategoryId = '', iParentCategoryId) =>
  `${API_DOMAIN}api/feed?nPage=${page}&nLimit=${limit}${iParentCategoryId && '&iParentCategoryId=671f8090784ed66097a5a93d'}${iCategoryId && `&iSimpleCategoryId=${iCategoryId}`}`
// export const endpointSearch = searchQuery => `${API_SEARCH_DOMAIN}${searchQuery}&lang=en&apikey=${API_KEY}`
