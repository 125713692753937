import React from 'react'

const CMSPages = ({ page }) => {
  return (
    <div className="cms">
      {page === 'terms-and-condition' ? (
        <>
          <h1>बाईसाहब के लिए नियम एवं शर्तें</h1>

          <p>
            बाईसाहब में आपका स्वागत है! हमारी वेबसाइट का उपयोग या उस तक पहुँच कर, आप निम्नलिखित नियमों और शर्तों का पालन करने और उनसे बंधे रहने के लिए सहमत होते हैं। कृपया इस साइट का उपयोग करने से पहले इन शर्तों को ध्यानपूर्वक पढ़ें। यदि आप इनमें से किसी भी शर्त से असहमत हैं, तो कृपया हमारी वेबसाइट का उपयोग करने से बचें।
          </p>

          <h4>1. नियमों की स्वीकृति</h4>
          <p>
            आपकी बाईसाहब तक पहुंच और उसका उपयोग विशेष रूप से इन नियमों और शर्तों के अधीन है। हम बिना किसी पूर्व सूचना के इन शर्तों को अपडेट करने का अधिकार सुरक्षित रखते हैं। किसी भी परिवर्तन के बाद वेबसाइट का निरंतर उपयोग करना आपके द्वारा नई शर्तों की स्वीकृति को दर्शाता है।
          </p>

          <h4>2. सामग्री की सटीकता</h4>
          <p>
            बाईसाहब विश्वसनीय स्रोतों पर आधारित समाचार लेख और जानकारी प्रदान करता है। हालांकि, हम किसी भी प्रकार की, स्पष्ट या अप्रत्यक्ष, किसी भी सामग्री की सटीकता, पूर्णता या विश्वसनीयता के बारे में कोई भी प्रतिनिधित्व या गारंटी नहीं देते हैं। प्रदान की गई जानकारी केवल सामान्य सूचना उद्देश्यों के लिए है, और हम पाठकों को स्वतंत्र रूप से जानकारी की सत्यता की पुष्टि करने की सलाह देते हैं।
          </p>

          <h4>3. बौद्धिक संपदा</h4>
          <p>
            इस वेबसाइट पर सभी सामग्री, जिसमें पाठ, ग्राफिक्स, लोगो और अन्य सामग्री शामिल हैं, बाईसाहब या इसके सामग्री आपूर्तिकर्ताओं की संपत्ति है। इस वेबसाइट पर किसी भी सामग्री की बिना अनुमति के पुनरुत्पादन या वितरण मना है, जब तक कि स्पष्ट रूप से लिखित रूप में अनुमति न दी गई हो।
          </p>

          <h4>4. उपयोगकर्ता आचार</h4>
          <p>
            आप सहमत हैं कि बाईसाहब का उपयोग जिम्मेदारी से करेंगे और ऐसी किसी भी गतिविधि में संलिप्त नहीं होंगे जो वेबसाइट की कार्यक्षमता को हानि पहुंचा सकती है या उसे प्रभावित कर सकती है। विशेष रूप से, आप सहमत हैं कि आप:
          </p>
          <ul>
            <li>वेबसाइट का उपयोग अवैध उद्देश्यों के लिए नहीं करेंगे।</li>
            <li>कोई भी आपत्तिजनक या हानिप्रद सामग्री पोस्ट नहीं करेंगे।</li>
            <li>वेबसाइट या उसके सिस्टम के किसी भी हिस्से तक अवैध रूप से पहुँच प्राप्त करने का प्रयास नहीं करेंगे।</li>
          </ul>

          <h4>5. अन्य वेबसाइटों के लिंक</h4>
          <p>
            हमारी वेबसाइट में तृतीय पक्ष वेबसाइटों के लिंक हो सकते हैं। ये लिंक आपकी सुविधा के लिए प्रदान किए गए हैं, और बाईसाहब को इन साइटों की सामग्री पर कोई नियंत्रण नहीं है। हम इन बाहरी साइटों पर जाने से उत्पन्न किसी भी प्रकार के नुकसान या हानि के लिए जिम्मेदार नहीं हैं।
          </p>

          <h4>6. जिम्मेदारी की सीमा</h4>
          <p>
            बाईसाहब आपकी इस वेबसाइट तक पहुँच या उसका उपयोग करने से उत्पन्न किसी भी प्रत्यक्ष, अप्रत्यक्ष, आकस्मिक, परिणामी, या दंडात्मक हानि के लिए जिम्मेदार नहीं है। इसमें गलतियाँ, चूक, रुकावटें, फाइलों का हटना, वायरस, देरी, या अन्य त्रुटियों से उत्पन्न होने वाली हानियाँ शामिल हैं।
          </p>

          <h4>7. गोपनीयता</h4>
          <p>
            हम आपकी गोपनीयता का सम्मान करते हैं। कृपया हमारी <a href="privacy-policy">गोपनीयता नीति</a> को देखें, जिसमें जानकारी दी गई है कि हम आपके व्यक्तिगत डेटा को कैसे एकत्रित, उपयोग और सुरक्षा करते हैं।
          </p>

          <h4>8. वेबसाइट में परिवर्तन</h4>
          <p>
            हम किसी भी समय बिना सूचना के वेबसाइट के किसी भी पहलू, जिसमें सामग्री और सुविधाएँ शामिल हैं, को संशोधित या समाप्त करने का अधिकार सुरक्षित रखते हैं।
          </p>

          <h4>9. प्रभावी कानून</h4>
          <p>
            इन नियमों और शर्तों पर भारत के कानूनों का पालन होगा। आपकी वेबसाइट के उपयोग से उत्पन्न होने वाले किसी भी विवाद का निपटारा [आपके क्षेत्राधिकार] के न्यायालयों की विशेष अधिकारिता के अधीन होगा।.
          </p>

          <h4>10. संपर्क करें</h4>
          <p>
            यदि आपको इन नियमों और शर्तों के बारे में कोई प्रश्न हो, तो कृपया हमसे <a href="mailto:support@baisahab.com">support@baisahab.com</a> पर संपर्क करें।
          </p>
        </>
      ) : (
        page === 'privacy-policy' && (
          <>
            <h1>बाईसाहब के लिए गोपनीयता नीति</h1>

            <p>
              बाईसाहब में, हम अपने उपयोगकर्ताओं की गोपनीयता को महत्व देते हैं। यह गोपनीयता नीति बताती है कि हम आपकी दी गई जानकारी को कैसे एकत्रित, उपयोग और सुरक्षा करते हैं जब आप हमारी वेबसाइट का उपयोग करते हैं। बाईसाहब तक पहुँचने या उसका उपयोग करने के द्वारा, आप इस नीति में उल्लिखित शर्तों से सहमत होते हैं।
            </p>

            <h4>1. हम कौन सी जानकारी एकत्रित करते हैं</h4>
            <p>
              हम बेहतर उपयोगकर्ता अनुभव प्रदान करने और अपनी सामग्री को सुधारने के लिए जानकारी एकत्रित करते हैं। हम जो जानकारी एकत्रित कर सकते हैं, उसमें शामिल हैं:
            </p>
            <ul>
              <li>
                <strong>उपयोग डेटा</strong>: आपकी डिवाइस, ब्राउज़र और हमारी साइट के साथ आपकी इंटरएक्शन (जैसे IP पता, ब्राउज़र प्रकार, पृष्ठों पर यात्रा, और प्रत्येक पृष्ठ पर समय) के बारे में जानकारी स्वचालित रूप से एकत्रित की जा सकती है।
              </li>
              <li>
                <strong>कुकीज़</strong>: हम आपकी ब्राउज़िंग अनुभव को बेहतर बनाने के लिए कुकीज़ और समान ट्रैकिंग तकनीकों का उपयोग कर सकते हैं। कुकीज़ हमें आपकी प्राथमिकताओं को याद रखने और यह समझने में मदद करती हैं कि उपयोगकर्ता हमारी साइट के साथ कैसे इंटरएक्ट करते हैं।
              </li>
            </ul>

            <h4>2. हम आपकी जानकारी का उपयोग कैसे करते हैं</h4>
            <p>हम एकत्रित की गई जानकारी का उपयोग करते हैं:</p>
            <ul>
              <li>हमारी वेबसाइट पर सामग्री और सेवाओं को प्रदान करने और सुधारने के लिए।</li>
              <li>यदि आपने हमारी मेलिंग सूची के लिए सब्सक्राइब किया है तो आपको न्यूज़लेटर और अपडेट भेजने के लिए।</li>
              <li>हमारे उपयोगकर्ता अनुभव और सामग्री को सुधारने के लिए वेबसाइट उपयोग पैटर्न का विश्लेषण करने के लिए।</li>
              <li>आपकी पूछताछ का उत्तर देने और आवश्यकतानुसार आपके साथ संवाद करने के लिए।</li>
            </ul>

            <h4>3. डेटा साझा करना और प्रकटीकरण</h4>
            <p>
              बाईसाहब आपकी व्यक्तिगत जानकारी को बाहरी पक्षों को बेचता, व्यापार करता या किसी अन्य तरीके से स्थानांतरित नहीं करता है। हालांकि, हम निम्नलिखित परिस्थितियों में डेटा साझा कर सकते हैं:
            </p>
            <ul>
              <li>
                <strong>सेवा प्रदाता</strong>: हम तृतीय-पक्ष सेवा प्रदाताओं को अपनी संचालन में सहायता करने के लिए नियोजित कर सकते हैं (जैसे, विश्लेषण, ईमेल डिलीवरी)। इन पक्षों को केवल उनकी कार्यों को निष्पादित करने के लिए आवश्यकतानुसार व्यक्तिगत जानकारी तक सीमित पहुंच होती है।
              </li>
              <li>
                <strong>कानूनी आवश्यकताएँ</strong>: हम जानकारी का प्रकटीकरण कर सकते हैं यदि कानून द्वारा आवश्यक हो या बाईसाहब या हमारे उपयोगकर्ताओं के अधिकारों, सुरक्षा या संपत्ति की रक्षा करने के लिए।
              </li>
            </ul>

            <h4>4. डेटा सुरक्षा</h4>
            <p>
              हम आपके डेटा की सुरक्षा के लिए प्रतिबद्ध हैं। हम आपकी व्यक्तिगत जानकारी तक अनधिकृत पहुंच, परिवर्तन, या प्रकटीकरण से बचाने के लिए उचित सुरक्षा उपाय लागू करते हैं। हालांकि, ऑनलाइन प्रसारण या भंडारण का कोई भी तरीका पूरी तरह से सुरक्षित नहीं होता है, और हम पूर्ण सुरक्षा की गारंटी नहीं दे सकते।
            </p>

            <h4>5. तृतीय-पक्ष लिंक</h4>
            <p>
              हमारी वेबसाइट में तृतीय-पक्ष वेबसाइटों के लिंक हो सकते हैं। हम इन बाहरी साइटों पर नियंत्रण नहीं रखते हैं या उनका समर्थन नहीं करते हैं, और हम उनकी सामग्री या गोपनीयता प्रथाओं के लिए जिम्मेदार नहीं हैं। हम आपको सलाह देते हैं कि आप किसी भी तृतीय-पक्ष साइटों की गोपनीयता नीतियों की समीक्षा करें जिन्हें आप विजिट करते हैं।
            </p>

            <h4>6. कुकी नीति</h4>
            <p>
              कुकीज़ हमारी उपयोगकर्ता अनुभव को सुधारने में मदद करती हैं, जिससे वेबसाइट आपकी क्रियाओं और प्राथमिकताओं को याद रख सकती है। आप अपने ब्राउज़र सेटिंग्स के माध्यम से कुकी प्राथमिकताओं को प्रबंधित कर सकते हैं, और अधिकांश ब्राउज़र आपको कुकीज़ को ब्लॉक करने की अनुमति देते हैं। हालांकि, कुकीज़ को निष्क्रिय करने से आपकी वेबसाइट की कुछ विशेषताओं का उपयोग प्रभावित हो सकता है।
            </p>

            <h4>7. बच्चों की गोपनीयता</h4>
            <p>
              हमारी वेबसाइट 13 वर्ष से कम उम्र के व्यक्तियों की ओर निर्देशित नहीं है, और हम जानबूझकर बच्चों से व्यक्तिगत जानकारी एकत्रित नहीं करते हैं। यदि हमें यह पता चलता है कि हमने 13 वर्ष से कम उम्र के बच्चे से डेटा एकत्रित किया है, तो हम उस जानकारी को तुरंत हटाने के कदम उठाएंगे।
            </p>

            <h4>8. इस गोपनीयता नीति में परिवर्तन</h4>
            <p>
              हम समय-समय पर इस गोपनीयता नीति को अपडेट कर सकते हैं ताकि हमारी प्रथाओं में बदलाव को प्रतिबिंबित किया जा सके। हम आपको सलाह देते हैं कि आप इस नीति की समय-समय पर समीक्षा करें। वेबसाइट का निरंतर उपयोग करना किसी भी संशोधन के बाद आपके द्वारा अद्यतन नीति की स्वीकृति को दर्शाता है।
            </p>

            <h4>9. आपके अधिकार और विकल्प</h4>
            <p>
              आपके स्थान के आधार पर, आपके पास अपनी व्यक्तिगत जानकारी के बारे में अधिकार हो सकते हैं, जैसे कि अपनी जानकारी का एक्सेस, अपडेट या हटाने का अधिकार। इन अधिकारों का उपयोग करने के लिए या हमारी गोपनीयता प्रथाओं के बारे में कोई प्रश्न पूछने के लिए, कृपया हमसे संपर्क करें।
            </p>

            <h4>10. हमसे संपर्क करें</h4>
            <p>यदि आपको इस गोपनीयता नीति या हमारे डेटा प्रथाओं के बारे में कोई प्रश्न या चिंता हो, तो कृपया हमसे संपर्क करें:</p>
            <p>
              <strong>ईमेल</strong>: <a href="mailto:support@baisahab.com">support@baisahab.com</a>
            </p>

          </>
        )
      )}
    </div>
  )
}

export default CMSPages
