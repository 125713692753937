import React from 'react';
import NavBar from './components/NavBar/NavBar';
import News from './components/News/News';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

function App() {
  const router = createBrowserRouter([
    {
      element: (
        <>
          <NavBar />
          <News />
        </>
      ),
      path: "/",
    },
    {
      element: (
        <>
          <NavBar />
          <News />
        </>
      ),
      path: "/:category",
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
