import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from 'react-bootstrap'
import Details from './Details/Details'
import { ReactComponent as ArrowIcon } from '../Images/ArrowIcon.svg'
import './NewsItem.css'
import { GCS_URL } from '../../config/config'

function NewsItem(props) {
  const { imageUrl, alt, description, title, published, urlNews } = props

  return (
    <Card className="card">
      <Card.Img className="card-img" variant="top" src={`${GCS_URL + imageUrl}`} alt={alt} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text className="card-description" dangerouslySetInnerHTML={{ __html: description }}></Card.Text>
        <Details channel={urlNews?.name} published={published} />
        <Button className="card-btn" href={urlNews?.redirectionUrl} target="_blank">
          Read more <ArrowIcon className="arrow-icon" />
        </Button>
      </Card.Body>
    </Card>
  )
}

NewsItem.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  channel: PropTypes.string,
  published: PropTypes.string,
  urlNews: PropTypes.object
}

export default NewsItem
