import React, { useContext, useEffect, useRef, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import { Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import logoImage from '../Images/logoImage.png'
import '../NavBar/NavBar.css'
import { HEADERS, endpointCatList } from '../../config/api'
import axios from 'axios'
import { GlobalContext } from '../../GlobalContext'

const cmsPages = [
  {
    name: 'नियम एवं शर्तें',
    slug: "terms-and-condition"
  },
  {
    name: 'गोपनीयता नीति',
    slug: "privacy-policy"
  }
]

function NavBar() {
  const location = useLocation();
  const pathname = decodeURI(location.pathname)
  const [navs, setNavs] = useState([])
  const { setCats } = useContext(GlobalContext)

  const navRef = useRef(null)
  // const [searchQuery, setSearchQuery] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true)

  const getCategories = async () => {
    try {
      const response = await axios.get(endpointCatList(), {
        headers: HEADERS
      })
      // setLoading(true);
      const parsedData = response?.data?.data?.categories
      setNavs([{ nav: '/', name: 'सभी-समाचार', eStatus: 'a' }, ...parsedData])
      setCats([{ nav: '/', name: 'सभी-समाचार', eStatus: 'a' }, ...parsedData])
      // setLoading(false);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line
  }, [])

  // const handleInputChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   navigate(`/search/${searchQuery}`);
  //   setSearchQuery("");
  //   setIsCollapsed(true);
  // };

  const handleNavClick = () => {
    setIsCollapsed(true)
  }

  // const isSearchButtonDisabled = searchQuery.trim() === "";

  return (
    <>
      <Navbar ref={navRef} className="navbar" variant="dark" expand="lg" fixed="top" expanded={!isCollapsed}>
        <Navbar.Brand className="nav-brand" href="/">
          <img width={150} height={45} src={logoImage} alt="Logo" className="logo" />
        </Navbar.Brand>
        {isCollapsed && (
          <Navbar.Toggle className="border-0" aria-controls="basic-navbar-nav" onClick={() => setIsCollapsed(!isCollapsed)} />
        )}

        {!isCollapsed && <IoCloseOutline size={40} className="close-btn" onClick={() => setIsCollapsed(!isCollapsed)} />}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto d-flex justify-content-between" onClick={handleNavClick}>
            <div className='d-flex flex-md-row flex-column'>
              {navs?.map(navItem => {
                const isActive = pathname?.replace(/\//g, '') === navItem.name
                if (navItem?.eStatus !== 'a') return <></>
                return (
                  <LinkContainer to={navItem.nav === '/' ? '/' : `/${navItem?.nav || navItem?.slug?.split('/')?.[1]}`} key={uuidv4()}>
                    <Nav.Link className={`nav-item ${isActive ? 'active' : ''}`}>{navItem.name?.replace(/-/g, ' ')}</Nav.Link>
                  </LinkContainer>
                )
              })}
            </div>
            <div className='d-flex flex-md-row flex-column'>
              {cmsPages?.map(navItem => {
                const isActive = pathname?.replace(/\//g, '') === navItem.name
                return (
                  <LinkContainer to={navItem.nav === '/' ? '/' : `/${navItem?.slug}`} key={uuidv4()}>
                    <Nav.Link className={`nav-item ${isActive ? 'active' : ''}`}>{navItem.name?.replace(/-/g, ' ')}</Nav.Link>
                  </LinkContainer>
                )
              })}
            </div>
            {/* </div> */}
          </Nav>
          {/* <Form className="search-form" onSubmit={handleSubmit}>
            <FormControl
              type="text"
              placeholder="Explore news..."
              className="form-input form-control-lg mt-lg-2 mt-md-2 mt-sm-2 mt-xl-0"
              value={searchQuery}
              onChange={handleInputChange}
            />
            <Button
              className="search-btn mt-lg-2 ml-2 mt-md-2 mt-sm-2 mt-xl-0"
              onClick={handleSubmit}
              disabled={isSearchButtonDisabled}
            >
              Search
            </Button>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default NavBar
