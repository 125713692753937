// GlobalContext.js
import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [cats, setCats] = useState([]);

  return (
    <GlobalContext.Provider value={{ cats, setCats }}>
      {children}
    </GlobalContext.Provider>
  );
};
