import React from 'react'
import { SpinnerContainer, LoadingCircle } from './index'

function Loading() {
  return (
    <SpinnerContainer>
      <LoadingCircle />
    </SpinnerContainer>
  )
}

export default Loading
